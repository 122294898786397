/* ======================
    
    ISI Component
    Version: 1.0

====================== */

class IsiComponent {

    constructor(el) {
    
        // Global Settings
        this.$el = el;
        this.isiExpanded = false;
        this.isiMobExpanded = false;
        this.staticIsi = this.$el.querySelector("#ISI"); // Set Static ISI ID
        this.pinnedIsi = this.$el.querySelector("#pinned-isi"); // Set Pinned ISI ID
        this.isiDskExpand = this.$el.querySelector("#expand-dsk-btn"); // Expand Button for Desktop Pinned ISI
        this.preventCollapse = false;  // Prevents recollapse on some Android Devices

        // Pull Custom Settings from data-attributes
        this.source = this.$el.getAttribute('data-source');
        this.expandCta = this.$el.getAttribute('data-isi-expand-cta');
        this.closeCta = this.$el.getAttribute('data-isi-close-cta');       
        this.scrollOnExpand = (this.$el.getAttribute('data-isi-scroll-on-expand') === 'true');

        // API required data
        if (this.source == "api") {

            this.lang = this.$el.getAttribute('data-isi-lang');
            this.country = this.$el.getAttribute('data-isi-country');
            this.brand = this.$el.getAttribute('data-isi-brand');
            this.audience = this.$el.getAttribute('data-isi-audience');
            this.env = (this.$el.getAttribute('data-isi-env') == 'prod' ? "" : "?env=test");
            this.isi_fetch = null; // Grabs content from ISI API

            // Generate URL
            this.apiURL = "https://isi.janssenos.com/isi/"+this.lang+"/"+this.country+"/"+this.brand+"/"+this.audience+this.env;

        }
        
        // Init
        this.init();

    }

    // Load Content and Add functionality to the ISI
    init() {

        // Load content from API (if selected)
        if (this.source == "api") {

            // Create Call
            const xhttp = new XMLHttpRequest();
            xhttp.overrideMimeType("application/json");
            xhttp.onreadystatechange = (response) => {

                if (response.currentTarget.readyState == 4 && response.currentTarget.status == 200) {

                    this.isi_fetch = JSON.parse(response.currentTarget.response);            
                    document.getElementById("janssen-isi-content-wrapper").innerHTML = this.isi_fetch.data;
                    document.getElementById("pinned-isi-content-wrapper").innerHTML = this.isi_fetch.data;

                    this.setButtonsFunctionality();

                } // endif
            };

            xhttp.open("GET", this.apiURL , true);
            xhttp.send();

        } else {

            document.getElementById("pinned-isi-content-wrapper").innerHTML = document.getElementById("janssen-isi-content-wrapper").innerHTML;
            this.setButtonsFunctionality();

        } // endif
        

        // Expand and Collapse Pinned ISI Button
        this.isiDskExpand.addEventListener("click", (event) => {
            this.expandIsi(event.target);
        });
    }

    // Set functionality
    setButtonsFunctionality() {

        // Add Buttons to Mobile Pinned ISI
        let isiWrapper = this.pinnedIsi.querySelector("#isi");
        let wisWrapper = this.pinnedIsi.querySelector("#indication");
        let isiMobExpandISI = this.createExpandClose('expand-mob-isi-btn');
        let isiMobExpandWis = this.createExpandClose('expand-mob-wis-btn');

        isiWrapper.appendChild(isiMobExpandISI);
        wisWrapper.appendChild(isiMobExpandWis);
        
        // Expand-Close Events
        isiMobExpandISI.addEventListener("click", () => {
            this.expandIsi(isiMobExpandISI,'isi');
        });
    
        isiMobExpandWis.addEventListener("click", () => {
            this.expandIsi(isiMobExpandWis,'indication');
        });

        // Show or Hide Pinned ISI
        this.setPinnedIsi();
        
        // Set Pinned ISI on scroll
        window.addEventListener('scroll', () => {
            this.setPinnedIsi();
        }); 

        // Collapse ISI on resize
        window.addEventListener('resize', () => {
            this.collapseOnResize();
        });

        // Remove Target Blank from Telephone links
        Array.from(this.$el.querySelectorAll('a[target="_blank"]'))
            .forEach(link => link.removeAttribute('target'));
    }

    // Create Expand/Close button
    createExpandClose(id) {

        let isiCTABtn = document.createElement("div");
            isiCTABtn.setAttribute("id",id);
            isiCTABtn.classList.add("isi-main__expand");
            isiCTABtn.innerHTML = this.expandCta;

        return isiCTABtn;
    }

    // Show or Hide Pinned ISI
    setPinnedIsi() {

        let scrollPosition = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop);
        let pinnedIsiPos = Math.round(this.staticIsi.offsetTop - window.innerHeight*0.75);
            
        // Set position of Pinned ISI when expanded
        if(this.isiExpanded) {
            pinnedIsiPos = Math.round(this.staticIsi.offsetTop - window.innerHeight*0.50);
        } // endif
    
        if (scrollPosition >= pinnedIsiPos) {
            this.pinnedIsi.classList.remove('show'); // hide
        } else {
            this.pinnedIsi.classList.add('show'); // show
        } // endif
    }

    // Expand ISI
    expandIsi(el,mobId=false) {

        // Mobile Functionality
        if (mobId) {

            let expandSection = this.pinnedIsi.querySelector("#"+mobId);
            this.preventCollapse = "mobile";
            
            // Collapse All first
            let mobilePanel = this.pinnedIsi.querySelectorAll(".isi-panel-pane");
            mobilePanel.forEach(panel => {
                panel.classList.remove('expand');
                panel.querySelector(".isi-main__expand").innerHTML = this.expandCta;
            });

            // Expande or Collapse if required
            if (!this.isiMobExpanded || this.isiMobExpanded != mobId) {
                expandSection.classList.add("expand");
                expandSection.querySelector(".pane-content").scrollTop = 0; // Scroll top
                this.disableBodyScroll(); // Lock Scroll on Body
                this.isiMobExpanded = mobId;
                this.isiExpanded = true;
            } else {
                expandSection.classList.remove("expand");
                this.enableBodyScroll();
                this.isiMobExpanded = false;
                this.isiExpanded = false;
            } // endif

        } else {

            this.isiExpanded = !this.isiExpanded;

        } // endif

        // Global Functionality
        if (!this.isiExpanded) {
            
            this.pinnedIsi.classList.remove("expand");
            el.innerHTML = this.expandCta;

        } else {

            // Check Desktop Expand Settings
            if (this.scrollOnExpand && !this.isiMobExpanded) {
                
                // Scroll
                this.staticIsi.scrollIntoView({
                block: "start",
                behavior: 'smooth'
                });

                this.isiExpanded = false;

            } else {
                
                // Expand
                this.pinnedIsi.classList.add("expand");
                el.innerHTML = this.closeCta;

            } // endif

        } // endif
    }

    // Collapse on resize
    collapseOnResize() {

        let screenWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
        
        // Collapse ISI on resize
        if (screenWidth <= 780 && this.preventCollapse != "mobile") {

            this.preventCollapse = "mobile";

            // Collapse Main Wrapper
            this.pinnedIsi.classList.remove("expand");
            this.isiDskExpand.innerHTML = this.expandCta;

            // Also Collapse Panels
            let mobilePanel = this.pinnedIsi.querySelectorAll(".isi-panel-pane");
            mobilePanel.forEach(panel => {
                panel.classList.remove('expand');
                panel.querySelector(".isi-main__expand").innerHTML = this.expandCta;
            });

            // Update states
            this.isiMobExpanded = false;
            this.isiExpanded = false;

        } else if (screenWidth > 780 && this.preventCollapse != "desktop") {

            // Collapse Main Wrapper
            this.pinnedIsi.classList.remove("expand");

            // Remove Body Scroll Lock
            this.enableBodyScroll();

            // Reset preventCollapse
            this.preventCollapse = "desktop";
            
        } // endif
    }

    disableBodyScroll() {
        document.body.classList.add('lock');
        /*document.ontouchmove = function (e) {
            e.preventDefault();
        }*/
    }
    
    enableBodyScroll() {
        document.body.classList.remove('lock');
        /*document.ontouchmove = function (e) {
            return true;
        }*/
    }
}

// Launch Component Scripts
document.addEventListener("DOMContentLoaded", function() {
    document.querySelectorAll('[data-controller="IsiComponent"]')
    .forEach((el) => {
        new IsiComponent(el);
    });
});